export const DASHBOARD_VIEWS = Object.freeze({
  REVIEW_REQUIRED: {
    name: 'Review Required',
    value: 'REVIEW_REQUIRED',
    title: 'Review Required Special Orders',
    subTitle:
      'Please review these special orders and contact dealer to update the order based on what needs to be reviewed.',
  },
  ACTIVE_ORDERS: {
    name: 'Active Orders',
    value: 'ACTIVE_ORDERS',
    title: 'Ready for Approval Orders',
  },
  APPROVED_ORDERS: {
    name: 'Approved Orders',
    value: 'APPROVED_ORDERS',
    title: 'Review Required Special Orders',
  },
  CANCELLED_ORDERS: {
    name: 'Cancelled Orders',
    value: 'CANCELLED_ORDERS',
    title: 'All Cancelled Orders',
  },
  EXPIRED_ORDERS: {
    name: 'Expired Orders',
    value: 'EXPIRED_ORDERS',
    title: 'All Expired Special Orders',
    subTitle: 'Please review these expired special orders and reactivate as needed.',
  },
});

export const APPROVED_ORDERS_VIEWS = Object.freeze({
  DASHBOARD: {
    name: 'Dashboard',
    value: 'DASHBOARD',
    tabIndex: 0,
    title: 'Current Extraction Window',
  },
  LIST_VIEW: {
    name: 'List View',
    value: 'LIST_VIEW',
    tabIndex: 1,
    title: 'All Approved Orders',
  },
  HISTORY: {
    name: 'History',
    value: 'HISTORY',
    tabIndex: 2,
    title: 'All Extracted Orders',
    status: 'Extracted',
  },
});

export const STORAGE_KEYS = Object.freeze({
  DASHBOARD_CONTEXT: 'dashboardCtx',
});

export const SPECIAL_ORDERS = Object.freeze({
  READY_FOR_APPROVAL_ORDERS: 'Ready for Approval Orders',
  VIEW_BY_SO_NO: 'View by SO No',
  SORTED_BY_SO_NO: 'Sorted by SO No',
  SO_NUMBER: 'SO Number',
  EXTERIOR: 'Exterior',
  PPO: 'PPO',
  SOLD_ORDER_DATE: 'Sold Order Date',
  INTERIOR: 'Interior',
  DEALER_CODE: 'Dealer Code',
  FIO: 'FIO',
  ITEMS: 'items',
  VIEW_BY: ' View by',
  SORT_BY: 'Sort by',
  APPROVE_SPECIAL_ORDERS: 'Approve Special order',
  REJECT_SPECIAL_ORDERS: 'Reject Special order',
});

export const CURRENT_EXTRACTION_WINDOW = Object.freeze({
  HEADER: 'Current Extraction Window',
  PENDING_CUSTOMER_CONFIRMATION: {
    name: 'Pending Customer Confirmation',
    value: 'Pending Customer Confirmation',
  },
  CUSTOMER_CONFIRMED: {
    name: 'Customer Confirmed',
    value: 'Extraction in Process',
  },
  EXTRACTED: {
    name: 'Extracted',
    value: 'Extracted',
  },
});

export const MESSAGES = Object.freeze({
  APPROVE_MESSAGE: 'Would you like to approve this special order?',
  REJECT_MESSAGE:
    'You are rejecting following special order. Please provide your reason for rejection.',
  APPROVE_FAILED: 'Failed to approve order',
});

export const ACTIONS = Object.freeze({
  APPROVE: 'Approve',
  REJECT: 'Reject',
  CANCEL: 'Cancel',
});

export const INPUT_LABELS = Object.freeze({
  DENIAL_REASON: 'Rejection Reason',
  COMMENTS: 'Comments',
});

export const OPTIONS = Object.freeze({
  REJECT_REASONS: ['Regularly Ordered Vehicle', 'Upcoming Model Year Changeover', 'Other'],
});

export const STATUS_CHIPS = Object.freeze({
  READY_FOR_APPROVAL: 'READY FOR APPROVAL',
  EXPIRED: 'EXPIRED',
  CUSTOMER_CONFIRMED: 'CUSTOMER CONFIRMED',
  EXTRACTED: 'EXTRACTED',
  PENDING_CUSTOMER_CONFIRMATION: 'PENDING CUSTOMER CONFIRMATION',
  EXTRACTION_IN_PROCESS: 'EXTRACTION IN PROCESS',
  REGION_DENIED: 'REGION DENIED',
  SPECS_FAILED200: '200SPECS FAILED',
  SPECS_INVALID200: '200SPECS INVALID',
  VALIDATION_FAILED: 'VALIDATION FAILED',
  CUSTOMER_REJECTED: 'CUSTOMER REJECTED',
  CONFIGURATION_INVALID: 'CONFIGURATION INVALID',
  QUOTA_ALLOWANCE_USED: 'QUOTA ALLOWANCE USED',
});
